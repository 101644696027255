export const APIoptions = {
	method: 'GET',
	headers: {
		'x-rapidapi-key': 'e98458b17emshbf55b9c1927b8f6p136153jsnc213cc750f95',
		'x-rapidapi-host': 'wft-geo-db.p.rapidapi.com'
	}
};

export const url = 'https://wft-geo-db.p.rapidapi.com/v1/geo';

export const WEATHER_URL = "https://api.openweathermap.org/data/2.5";
export const WEATHER_API_KEY = "1bb119f7b5e3bfc528319a44087cf462";